<template>
  <div id="paginaLoader">
    <!-- Elementi Pagina----------------------------------------------------------------------------------- -->
    <!-- Spinner -->
    <b-col xl="12" md="12" class="pt-1 text-center" v-show="true">
      <b-spinner label="Loading..."></b-spinner>
    </b-col>
    <b-row class="match-height">
    <!-- Card con immagine teatro -->
      <b-col xl="12" md="12">
        <b-card title="Inizializzazione in corso.." tag="article" class="mb-2" height="100">

          <!-- card body -->
          <b-img src="~@/assets/images/home/loading-cctv.jpg" fluid alt="Responsive image"></b-img>

        </b-card>
      </b-col>

    </b-row>

  </div>
</template>
<script>
import Vue from "vue";
import { Browser } from '@syncfusion/ej2-base';


import useJwt from '@/auth/jwt/useJwt'

import {
  LayoutPlugin,
  // TabsPlugin,
  CardPlugin,
  // DropdownPlugin,
  ImagePlugin,
  // EmbedPlugin,
  // TablePlugin,
  // LinkPlugin,
  // AlertPlugin,
  // ProgressPlugin,
  // ToastPlugin,
  // FormGroupPlugin,
  // FormInputPlugin,
  // FormCheckboxPlugin,
  // FormSelectPlugin,
  // CarouselPlugin,
  // FormRatingPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";

// import {
//   DateTimePickerPlugin,
//   DateRangePickerPlugin,
//   DatePickerPlugin,
//   TimePickerPlugin,
//   CalendarPlugin,
// } from "@syncfusion/ej2-vue-calendars";
// import {
//   ButtonPlugin,
//   CheckBoxPlugin,
//   SwitchPlugin,
// } from "@syncfusion/ej2-vue-buttons";
// import {
//   TextBoxPlugin,
//   NumericTextBoxPlugin,
//   MaskedTextBoxPlugin,
//   SliderPlugin,
//   UploaderPlugin,
// } from "@syncfusion/ej2-vue-inputs";
// import {
//   DropDownListPlugin,
//   MultiSelectPlugin,
//   ListBoxPlugin,
//   CheckBoxSelection,
// } from "@syncfusion/ej2-vue-dropdowns";

// import { SpreadsheetPlugin } from "@syncfusion/ej2-vue-spreadsheet";
// import {
//   SchedulePlugin,
//   Day,
//   Week,
//   WorkWeek,
//   Month,
//   Agenda,
// } from "@syncfusion/ej2-vue-schedule";
// import {
//   DocumentEditorContainerPlugin,
//   DocumentEditorContainerComponentm,
//   Toolbar,
// } from "@syncfusion/ej2-vue-documenteditor";
// import { DiagramPlugin } from "@syncfusion/ej2-vue-diagrams";
// import {
//   MapsPlugin,
//   Bubble,
//   Zoom,
//   MapsTooltip,
//   Marker,
//   NavigationLine,
// } from "@syncfusion/ej2-vue-maps";
// import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
// import {
//   GridPlugin,
//   ForeignKey,
//   Edit as GridEdit,
//   Toolbar as GridToolbar,
//   Sort as GridSort,
//   Filter as GridFilter,
// } from "@syncfusion/ej2-vue-grids";
// import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";

// use bootstrap-vue
// Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
// Vue.use(DropdownPlugin);
Vue.use(ImagePlugin);
// Vue.use(EmbedPlugin);
// Vue.use(TablePlugin);
// Vue.use(LinkPlugin);
// Vue.use(AlertPlugin);
// Vue.use(ProgressPlugin);
// Vue.use(ToastPlugin);
// Vue.use(FormGroupPlugin);
// Vue.use(FormInputPlugin);
// Vue.use(FormCheckboxPlugin);
// Vue.use(FormSelectPlugin);
// Vue.use(CarouselPlugin);
// Vue.use(FormRatingPlugin);
Vue.use(SpinnerPlugin);
// use Syncfusion
// Vue.use(TextBoxPlugin);
// Vue.use(NumericTextBoxPlugin);
// Vue.use(MaskedTextBoxPlugin);
// Vue.use(SliderPlugin);
// Vue.use(UploaderPlugin);
// Vue.use(DateRangePickerPlugin);
// Vue.use(DatePickerPlugin);
// Vue.use(DateTimePickerPlugin);
// Vue.use(TimePickerPlugin);
// Vue.use(DropDownListPlugin);
// Vue.use(MultiSelectPlugin);
// Vue.use(ButtonPlugin);
// Vue.use(CheckBoxPlugin);
// Vue.use(SwitchPlugin);
// Vue.use(SpreadsheetPlugin);
// Vue.use(SchedulePlugin);
// Vue.use(DocumentEditorContainerPlugin);
// Vue.use(DiagramPlugin);
// Vue.use(MapsPlugin);
// Vue.use(DialogPlugin);
// Vue.use(GridPlugin);
// Vue.use(DropDownButtonPlugin);
// Vue.use(ListBoxPlugin);
// Vue.use(CalendarPlugin);

export default Vue.extend({
  data: function () {
    return {
    }
  },
  created: function () {
    this.$router.push({path: "/vendite"});
  },

  provide: {
  },
  methods: {
  }
});
</script>

<style lang="scss" >

</style>